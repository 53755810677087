<template>
  <div>
    <h2 class="title my-3">Organization Management</h2>
    <div class="row mb-3">
      <div class="col-lg-6">
        <b-input-group>
          <b-form-input type="search" placeholder="Search" v-model="filter" @input="debouncedGetOrganisationsInfo" />
          <b-input-group-append>
            <span class="input-group-text">
              <i class="bi bi-search"></i>
            </span>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-4 mb-4 mb-md-2">
        <b-form-input
          v-model="form.orgName"
          :class="{ 'is-invalid': !form.validName }"
          placeholder="Organization Name"
        />
        <div class="invalid-feedback">Name is required</div>
      </div>
      <div class="col-lg-4 mb-4 mb-md-2">
        <b-form-input
          v-model="form.orgDomain"
          :class="{ 'is-invalid': !form.validDomain }"
          placeholder="OrgDomain.com"
        />
        <div class="invalid-feedback">OrgDomain is required</div>
      </div>
      <div class="col-lg-4 mb-2">
        <button @click="createNewDomain" class="btn btn-outline-info">
          Create Organization
        </button>
      </div>
    </div>
    <b-table
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="0"
      :busy ="isBusy"
      striped
      bordered
      stacked="md"
      @sort-changed="onSortChanged"
      show-empty
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>Loading organizations...</strong>
        </div>
      </template>
      <template #cell(name)="row">
        <p class="mb-0">{{ row.item.name }}</p>
        <span class="text-muted small">{{ row.item.id }}</span>
      </template>
      <template #cell(worldMapStatus)="row">
        <div class="d-flex  justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="toggleWorldMap(row.item.id, row.item.worldMapStatus)"
            v-model="row.item.worldMapStatus"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(workplaces)="row">
        <div
          class="d-flex justify-content-around justify-content-lg-between align-items-center"
        >
          {{ row.item.workplaces}}
          <button
            @click="addToDomain(row.item.id)"
            class="btn btn-sm btn-outline-info"
          >
            Add 10
          </button>
        </div>
      </template>
      <template #cell(visibilityGroups)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="toggleVisibilityGroups(row.item.id, row.item.visibilityGroups)"
            v-model="row.item.visibilityGroups"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(analyticsStatus)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="toggleAnalytics(row.item.id, row.item.analyticsStatus)"
            v-model="row.item.analyticsStatus"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(jobDoneStatus)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="toggleJobDone(row.item.id, row.item.jobDoneStatus)"
            v-model="row.item.jobDoneStatus"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(whiteLabelStatus)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="togglewhiteLabel(row.item.id, row.item.whiteLabelStatus)"
            v-model="row.item.whiteLabelStatus"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(passwordProtectionStatus)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="togglePasswordProtection(row.item.id, row.item.passwordProtectionStatus)"
            v-model="row.item.passwordProtectionStatus"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(autoTranslate)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="toggleAutoTranslate(row.item.id, row.item.autoTranslate)"
            v-model="row.item.autoTranslate"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(customRoles)="row">
        <div class="d-flex justify-content-start justify-content-lg-center">
          <b-form-checkbox
            @change="togglecustomRoles(row.item.id, row.item.customRoles)"
            v-model="row.item.customRoles"
            switch
            size="lg"
          />
        </div>
      </template>
      <template #cell(latestAction)="row">
        {{ row.item.latestAction ? new Date(row.item.latestAction).toLocaleString('en-GB') : '--'}}
      </template>
      <template #cell(actions)="row">
        <button @click="deleteDomain(row.item.id)" class="btn btn-sm btn-dark">
          <i class="bi bi-trash-fill"></i>
        </button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @input="getOrganisationsInfo"
    />
  </div>
</template>

<script>
import axios from "axios";
import "bootstrap-vue/dist/bootstrap-vue.css";
export default {
  name: "OrganizationManager",
  props: {
    accesstoken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseURL: `${this.$address}api`,
      form: {
        orgName: "",
        orgDomain: "",
        validName: true,
        validDomain: true,
        isBusy:false,
      },
      items: [],
      fields: [
        { key: "name", label: "Organisation", sortable: true },
        { key: "domain", label: "Domain" },
        { key: "worldMapStatus", label: "AR Worldmap" },
        { key: "workplaces", label: "Projects", sortable: true },
        { key: "visibilityGroups", label: "Groups" },
        { key: "analyticsStatus", label: "Analytics" },
        { key: "jobDoneStatus", label: "Job Done" },
        { key: "whiteLabelStatus", label: "White Label" },
        { key: "passwordProtectionStatus", label: "Password Protection" },
        { key: "autoTranslate", label: "Auto translate" },
        { key: "customRoles", label: "Role Management" },
        { key: "latestAction", label: "Last action", sortable: true },
        { key: "actions", label: "Delete" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      totalRows:1,
      sortBy: '',
      sortDesc: false,
      debouncedGetOrganisationsInfo: null
    };
  },
  created() {
    this.getOrganisationsInfo();
    this.debouncedGetOrganisationsInfo = this.debounce(this.getOrganisationsInfo, 300);
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
    async getOrganisationsInfo() {
      try {
        this.isBusy = true;

        const { data } = await axios.get(`${this.baseURL}/Organisations/getOrganisationsInfo`, {
          params: {
            access_token: this.accesstoken,
            page: this.currentPage,
            pageSize: this.perPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            search: this.filter
          }
        });

        this.items = data.result.result || [];
        this.totalRows = data.result.count || 0;

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.error(error);
      }
    },
    onSortChanged(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;

      this.getOrganisationsInfo();
    },
    validateForm() {
      this.form.orgName = this.form.orgName.trim();
      this.form.orgDomain = this.form.orgDomain.trim();
      this.form.orgName.length > 0
        ? (this.form.validName = true)
        : (this.form.validName = false);
      this.form.orgDomain.length > 0
        ? (this.form.validDomain = true)
        : (this.form.validDomain = false);
    },
    async createNewDomain() {
      this.validateForm();
      if (!this.form.validName || !this.form.validDomain) return;
      try {
        const { data } = await axios.post(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { name: this.form.orgName, domain: this.form.orgDomain }
        );
        this.form.orgName = "";
        this.form.orgDomain = "";
        this.items.push({ ...data, workplaces: 0 });
        this.addToDomain(data.id);
      } catch (error) {
        console.error(error);
      }
    },
    async addToDomain(orgId) {
      await axios.post(
        `${this.baseURL}/Workplaces/Add2?access_token=${this.accesstoken}`,
        { orgId }
      );
      this.$bvModal
        .msgBoxOk("has been added to the organisation", {
          title: "Confirmation - 10 New Projects",
          okVariant: "success",
        })
        .then(() => {
          const idx = this.items.findIndex((item) => item.id === orgId);
          this.items[idx].workplaces += 10;
        });
    },
    async toggleWorldMap(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, worldMapStatus: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async toggleVisibilityGroups(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, visibilityGroups: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async toggleAnalytics(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, analyticsStatus: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async toggleJobDone(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, jobDoneStatus: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async togglewhiteLabel(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, whiteLabelStatus: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async togglePasswordProtection(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, passwordProtectionStatus: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async toggleAutoTranslate(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, autoTranslate: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async togglecustomRoles(orgId, status) {
      try {
        await axios.patch(
          `${this.baseURL}/Organisations?access_token=${this.accesstoken}`,
          { id: orgId, customRoles: status }
        );
      } catch (error) {
        console.error(error);
      }
    },
    async deleteDomain(orgId) {
      this.$bvModal
        .msgBoxConfirm(
          "This action will delete all the data from this organisation (Users, Projects and instructions).",
          {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
          }
        )
        .then(async (confirmDelete) => {
          if (confirmDelete) {
            await axios.delete(
              `${this.baseURL}/Organisations/${orgId}?access_token=${this.accesstoken}`
            );
            const idx = this.items.findIndex((item) => item.id === orgId);
            this.items.splice(idx, 1);
          }
        });
    },
  },
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 50px;
}
::v-deep .table > tbody > tr > td {
vertical-align: middle !important;
}
::v-deep .table thead th {
vertical-align: middle !important;
}
</style>
