export const UserPermissions = {
    CREATE_USER: true,
    EDIT_USER: true,
    DELETE_USER: true,
    LOGIN_APP: true,
    LOGIN_DASHBOARD: true,
    CREATE_PATTERN: true,
    DELETE_PATTERN: true,
    MANAGE_OBJECT: true,
    DELETE_OBJECT: true,
    MANAGE_ICON: true,
    DELETE_ICON: true,
    MANAGE_WEB_PASSWORD: true,
    EDIT_WHITE_LABEL: true,
    VIEW_ANALYTICS: true,
    EXPORT_PDF: true,
    EXPORT_QR: true,
    VIEW_JOB_DONE: true,
    MANAGE_GROUPS: true,
    MANAGE_GROUP_PERMISSIONS: true,
    MANAGE_INSTRUCTIONS: true,
    EDIT_GROUP_DETAILS: true,
    EDIT_GROUP_USERS: true,
    EDIT_GROUP_PROJECTS: true,
    CREATE_INSTRUCTION: true,
    DELETE_INSTRUCTION: true,
    EDIT_INSTRUCTION: true,
    MOVE_INSTRUCTION: true,
    PROTECT_INSTRUCTION: true,
    EDIT_PASSWORD: true,
    APPROVE_TECHNICALLY: true,
    APPROVE_DATA_SECURITY: true,
    MANAGE_WEB_LINK: true,
    EXPORT_PDF_GROUP: true,
    EXPORT_QR_GROUP: true,
    MANAGE_ROLES: true
  };